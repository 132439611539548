<template>
  <div class="e-counselor-avatar" >
    <button class="e-counselor-avatar__avatar-button" :class="{'e-counselor-avatar__avatar-button--active': counselingProfileOpen}" @click.stop="openCounselingProfile">
      <div
        v-if="avatar"
        :style="{ backgroundImage: `url(${avatar})` }"
        class="e-counselor-avatar__avatar e-counselor-avatar__avatar-image"
      />

      <div v-else class="e-counselor-avatar__avatar">
        <IconUser />
      </div>
    </button>
    <dialog @click.stop="() => {}" class="e-counselor-avatar__avatar-dialog" ref="dialogElement" :open="counselingProfileOpen">
      <header>
        <h4 class="avatar-title">{{ author }}</h4>
        <button autofocus @click.stop="closeCoounselingProfile" :aria-label="$t('system.close')"><IconClose /></button>
      </header>
      <div class="column-wrapper">
        <div class="avatar-column">
          <div
            v-if="avatar"
            :style="{ backgroundImage: `url(${avatar})` }"
            class="e-counselor-avatar__avatar e-counselor-avatar__avatar-image"
          />

          <div v-else class="e-counselor-avatar__avatar">
            <IconUser />
          </div>
        </div>
        <span v-html="$sanitize(introText)" class="intro-text-column"/>
      </div>
    </dialog>
  </div>
</template>
<script>
import IconUser from 'assets/icons/user.svg'
import IconClose from 'assets/icons/close.svg'

export default {
  name: 'CounselorAvatar',
  components: {
    IconUser,
    IconClose
  },
  props: {
    avatar: {
      type: String,
    },
    introText: {
      type: String,
    },
    author: {
      type: String,
    },
  },
  data: () => ({
    counselingProfileOpen: false
  }),
  methods: {
    closeListener() {
      this.counselingProfileOpen = false
      this.$refs.dialogElement.removeEventListener('close', this.closeListener)
    },
    openCounselingProfile() {
      this.$refs.dialogElement.addEventListener('close', this.closeListener)
      this.$refs.dialogElement.showModal()
      this.counselingProfileOpen = true
    },
    closeCoounselingProfile() {
      this.$refs.dialogElement.close()
      this.counselingProfileOpen = false
    }
  }
}
</script>
<style lang="scss">
@import '_assets/counseloravatar';
</style>